import { version } from '../../package.json';

export const environment = {
  production: true,
  apiHost: `https://api.sendphoto.io`,
  domain: 'sendphoto.io',
  host: 'https://app.sendphoto.io',
  thumbnailerHost: `https://thumbs.sendphoto.io`,
  mediaHost: 'https://media.sendphoto.io',
  uploadHost: 'https://upload.sendphoto.io',
  turnstileSiteKey: '0x4AAAAAAAYWzjUVnvtYXUKi',
  stripeKey: 'pk_live_51NVXjxFjW2zoMQh1lMvi3T4XMTCDB9gDRMogCVX04bbqjH4SnvpplyQnceig6V2zUcQGUCBOyMI1NrZKWIVQSDaz00eVBr4WYQ',
  ssl: true,
  sentryDSN: 'https://874f5eec425f31d30bf011d46b4af145@o4506173594075136.ingest.us.sentry.io/4507961637470208',
  version: version,
  whitelabelIpV4: '116.202.188.234',
  whitelabelIpV6: '2600:3c06:1::acea:d3a6',
  posthogKey: 'phc_uiMHhQoKJ5eFPSu32UqyGDOqYpAOI5s4lRsJUmcwDm1',
  posthogUiHost: 'https://us.i.posthog.com',
  posthogApiHost: 'https://tr.sendphoto.io'
};
