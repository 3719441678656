{
  "1" : {
    "completed": false,
    "title": "Add Your Photos! \uD83D\uDCF8✨",
    "description": "Time to make your gallery shine! Tap this button to upload your images.",
    "placement": "left"
  },
  "2": {
    "completed": false,
    "title": "Edit Gallery Title ✏\uFE0F",
    "description": "Personalize your gallery by editing the title directly!",
    "placement": "bottom"
  },
  "3": {
    "completed": false,
    "title": "Organize Your Photos \uD83D\uDCC2",
    "description": "Create folders to keep things tidy! Perfect for sorting by location or separating edited photos from raw ones",
    "placement": "bottom"
  },
  "4": {
    "completed": false,
    "title": "Add a Cover and Background Photos \uD83D\uDDBC\uFE0F",
    "description": "Make your gallery stand out! Choose a cover photo here. Background photos will be displayed behind your gallery.",
    "placement": "right"
  },
  "5": {
    "completed": false,
    "title": "Configure Your Gallery ✏\uFE0F",
    "description": "Personalize your gallery by editing its title, description, watermark, expiration, and more!",
    "placement": "bottom"
  },
  "6": {
    "completed": false,
    "title": "Single Click Share Share Your Gallery \uD83C\uDF10",
    "description": "Get the link, set a password, email it, and even control download limits—all in one place!",
    "placement": "right"
  },
  "7": {
    "completed": false,
    "title": "Gallery Look and Feel \uD83D\uDC40",
    "description": "Preview your gallery and customize its look and feel. You can pick a font, colors, and more!",
    "placement": "right"
  }
}
